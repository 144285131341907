import React, { useContext, useState, useEffect } from 'react';
import "./InProcess.css"

const InProcess = (() => {

    return (
        <div className='in_process_block'>В процессе доработки</div>
    );
});

export default InProcess;
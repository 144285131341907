import { useEffect, useState } from 'react';
import { fetchOneProtected_Data } from '../http/Data_API/Protected_Data_API';

const UsingCanvas = false;

//  Защищённое изображение.
//  Делает запрос к серверу, который возвращает base64 представление изображения.
//  При запросе через клиентское приложение, никаких доп. действий не требуется, т.к. браузер знает,
//  как работать с такими изображениями.
//  TODO: адаптировать стили или компонент Zoom для использования с canvas, после чего, можно сделать переменную 'UsingCanvas' равной 'true'.
const ImageProtected = ({ url, className }) => {

    const elementId = 'canvas_' + parseInt(Math.random() * 100_000_000);

    useEffect( () => {
        fetchOneProtected_Data(url).then( (image) => {

            if (!UsingCanvas)
            {
                const img = document.getElementById(elementId);
                img.src = 'data:image/jpeg;base64,' + image.data;
                return;
            }

            const canvas = document.getElementById(elementId);
            if (!canvas)
                return;

            const context = canvas.getContext('2d');

            const decodedImg = new Image();
            decodedImg.src = 'data:image/jpeg;base64,' + image.data;

            decodedImg.addEventListener('load', () => {
                context.drawImage(decodedImg, 0, 0, decodedImg.width, decodedImg.height);
            });
        });
    }, [] );

    if (!url)
        return;

    if (UsingCanvas)
        return <canvas id={elementId} className={className}></canvas>;
    else
        return <img id={elementId} className={className} />;
};

export default ImageProtected;
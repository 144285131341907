import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import ECG_Data_Con_Parameters_Table from "../../components/ECG/ECG_Data_Con_Parameters_Table";
import ECG_Data_Options_Table from "../../components/ECG/ECG_Data_Options_Table";
import ECG_Data_OptionsDefault_Table from "../../components/ECG/ECG_Data_OptionsDefault_Table";
import ECG_Data_Images_Display from "../../components/ECG/ECG_Data_Images_Display";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import { PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { ECG_EntriesPerPage } from './ECG_Categories_Page';
import QuizComponent from '../../components/Quiz';
import Pages from '../../components/Pages';

const ECG_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();
    const selectedGender = new URLSearchParams(location.search).get('gender');


    useEffect(() => {
        PrepareItemPage(location, user.language, selectedGender, ECG_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData?.item)
        return;


    return <div className='ecg_page_block'>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/ekg_icon.png' alt='page_title_icon'></img>{t('ECG_title')}
        </div>
        <div className="flex_container" key={pageData.item.id}>
            <div className='flex_container_title'>
                <div className='item flex_item_1'>
                    {pageData.category['name_' + user.language]}
                </div>
                <TopCaseNavigation className='top_case_navigation_block' pageData={pageData} />
                <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
            </div>
            <hr />
            <br></br>
            <div className='birth_and_gender_grid'>
                <div className='case_gender'>
                    <div className='case_gender_block'>
                        <div className="case_text_info">{t('gender')}</div>
                        {pageData.item.gender['name_' + user.language]}</div>
                </div>
                <div className='case_birth'>
                    <div className='case_birth_date'>
                        <div className='case_text_info'>{t('age')}</div>
                        {pageData.item.date_of_birth}</div>
                </div>
                <div className='case_conclusion'>
                    <div className='case_text_block_bold'>
                        <div className='case_icon'>
                            <div className='case_text_info'>{t('conclusion')}</div>
                            <img src='/icons/case_icon.svg'></img>
                        </div>
                        {pageData.item['diagnosis_coclusion_' + user.language]}</div>
                </div>
                <div className='case_results' style={{ display: pageData.item['diagnosis_name_' + user.language] ? 'block' : 'none' }}>
                    <div className='case_text_block'>
                        <div className='case_text_info'>{t('diagnosis')}</div>
                        {pageData.item['diagnosis_name_' + user.language]}</div>
                </div>
            </div>
            <ECG_Data_Con_Parameters_Table data={pageData.item.ecg_data_con_parameters} />
            <ECG_Data_Options_Table data={pageData.item.ecg_options} />
            <ECG_Data_OptionsDefault_Table data={pageData.item.ecg_optionsDefault} />

            <div className='ecg_slider_info'>
                <div className='ecg_slider_title'>{t('navigation_slider')}</div>
                <hr></hr>
                {t('ECG_navigation_slider_help_1')}<img className='keyboard_back' src='/icons/back_key.svg' />{t('ECG_navigation_slider_help_2')}<img className='keyboard_next' src='/icons/next_key.svg' />
                {t('ECG_navigation_slider_help_3')}<img className='mouse_icon' src='/icons/mouse.svg' />{t('ECG_navigation_slider_help_4')}</div>
            <ECG_Data_Images_Display imageurl={pageData.item.images_path} />
            <BottomCaseNavigation pageData={pageData} />
        </div>
    </div>;
};

export default ECG_Data_Entrie_Page;

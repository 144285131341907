import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import { Botany_CATEGORIES_ROUTE, Botany_DATA_ENTRIE, DEMO_VERSION_ROUTE } from '../../utils/consts';
import ImageProtected from '../ProtectedImage';

const Botany_Data_EntriesItem = ({ item }) => {
    const { user } = useContext(Context);
    const history = useNavigate();
    const { t } = useTranslation();

    const TryNavigateToCase = () => {
        if (user.access_level.demo_access && !item.demo_access) {
            history(DEMO_VERSION_ROUTE);
            return;
        }

        history(`${Botany_CATEGORIES_ROUTE}${Botany_DATA_ENTRIE}/${item.id}`);
    };

    return <div className="case_preview-item1" onClick={TryNavigateToCase}>
        {item.demo_access && user.access_level.demo_access && <div className='demo_version_tif_preview'>
            {t("Demo")}
        </div>}
        <ImageProtected url={item.images_preview_path} />
        <div className='case_preview_info1'>
            <div className='case_preview_title1'>
                {item['name_' + user.language]}
            </div>
        </div>
    </div>;
};

export default Botany_Data_EntriesItem;